//=========== SPINNER =============
/*
$(function () {

    alert('da raiz');

    global.spinner = function (message) {
        if (message == null) {
            message = '';
        }
        $(document).find('html').prepend('<div class="spinner-box">\n' +
            '        <div class="spinner-border m-5" role="status">\n' +
            '            <span class="sr-only">Loading...</span>\n' +
            '        </div>\n' +
            '        <div>' + message + '</div>\n' +
            '    </div>').addClass('loading');
    }

    global.Progress = class {
        constructor(element, type, onComplete) {
            this.element = element;
            this.type = type;
            this.onComplete = onComplete;
            let html =
                '<div class="progress">' +
                '<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>\n' +
                '</div>';
            $(this.element).prepend(html);
            $(this.element).find('.progress-bar').addClass('bg-' + this.type);
        }

        completion(percentage) {
            if (percentage >= 100) {
                this.onComplete();
                percentage = 100;
            }
            if (percentage < 0) {
                percentage = 0;
            }
            $(this.element).find('.progress-bar').css('width', percentage + '%').attr('aria-valuenow', percentage);
        }

        dismiss() {
            $(this.element).find('.progress').remove();
        }

        static dismiss() {
            $(document).find('.progress').remove();
        }
    };

    global.dismiss = function () {
        $(document).find('.spinner-box').remove();
        $(document).find('html').removeClass('loading');
    }

});
//==================================

//====== CUSTOM DELAY BEFORE FETCH OBJECTS IN AN AUTO SEARCH INPUT =============
var timer;
$(function () {
    global.delay = function (mapCallback, ms) {
        timer = 0;
        return function () {
            var context = this, args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                mapCallback.apply(context, args);
            }, ms || 0);
        };
    }
    global.cancelDelay = function () {
        clearTimeout(timer);
    }

    global.jquery_tooltip_start = function () {
        $(document).tooltip({
            position: {
                my: "right bottom-20",
                at: "right top",
                using: function (position, feedback) {
                    $(this).css(position);
                    $("<div>")
                        .addClass("arrow")
                        .addClass(feedback.vertical)
                        .addClass(feedback.horizontal)
                        .appendTo(this);
                }
            }
        });
    }

});
//======================================================
 */
